import ApiService, { insertParams } from "@/util/api.service";

const ENDPOINTS = {
  CARD_PAYMENT: "packets/{barcode}/card_terminal_payment",
  CARD_PAYMENT_DETAIL: "card_terminal_payments/{id}",
  CARD_PAYMENT_CANCEL: "card_terminal_payments/{id}/cancel",
  SMS_PAYMENT: "packets/{barcode}/sms_payment",
  SMS_PAYMENT_SIGNATURE: "sms_payments/{id}/card_signature",
  SMS_PAYMENT_DETAIL: "sms_payments/{id}",
  SMS_PAYMENT_CANCEL: "sms_payments/{id}/cancel",
  CASH_PAYMENT: "packets/{barcode}/cash_payment",
  PAYMENT_RETURN: "packets/{barcode}/payment_return",
  PAYMENT_RETURN_DETAIL: "payment_returns/{id}",
  PRINT_SMS_SIGNATURE_CHECK: "sms_payments/{id}/print_signature_check"
};

export class PaymentService extends ApiService {
  // CARD PAYMENTS
  static postCardTerminalPayment = barcode => {
    return this.post(insertParams(ENDPOINTS.CARD_PAYMENT, { barcode }));
  };

  static getCardTerminalDetail = id => {
    return this.get(insertParams(ENDPOINTS.CARD_PAYMENT_DETAIL, { id }));
  };

  static patchCardTerminalCancel = id => {
    return this.patch(insertParams(ENDPOINTS.CARD_PAYMENT_CANCEL, { id }));
  };

  // SMS PAYMENTS
  static postSMSPayment = barcode => {
    return this.post(insertParams(ENDPOINTS.SMS_PAYMENT, { barcode }));
  };

  static getSMSPaymentDetail = id => {
    return this.get(insertParams(ENDPOINTS.SMS_PAYMENT_DETAIL, { id }));
  };

  static postSMSPaymentSignature = (id, matching) => {
    return this.post(insertParams(ENDPOINTS.SMS_PAYMENT_SIGNATURE, { id }), {
      matching
    });
  };

  static patchSMSPaymentCancel = id => {
    return this.patch(insertParams(ENDPOINTS.SMS_PAYMENT_CANCEL, { id }));
  };

  // CASH PAYMENTS
  static postCashPayment = ({ barcode, cod }) => {
    return this.post(insertParams(ENDPOINTS.CASH_PAYMENT, { barcode }), {
      ...cod
    });
  };

  // PAYMENT RETURNS
  static postPaymentReturn = barcode => {
    return this.post(insertParams(ENDPOINTS.PAYMENT_RETURN, { barcode }));
  };

  static getPaymentReturnDetail = id => {
    return this.get(insertParams(ENDPOINTS.PAYMENT_RETURN_DETAIL, { id }));
  };

  // PRINTS
  static postPrintSMSPaymentSignatureCheck = id => {
    return this.post(insertParams(ENDPOINTS.PRINT_SMS_SIGNATURE_CHECK, { id }));
  };
}

export default PaymentService;
